import queryString from 'query-string'
import React, { useMemo, useState } from 'react'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import http from '../../utils/helpers'
import Loader from '../loader'
import Error from '../error'
import { Col, Empty, Pagination, Row } from 'antd'
import { useTranslation } from 'react-i18next'

const SecondInnerPage = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { title } = queryString.parse(window.location.search)
    const { t } = useTranslation()

    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(24)

    const filter = useMemo(() => {
        return {
            product: params.id,
            page: currentPage,
            page_size: pageSize,
        }
    }, [params, currentPage, pageSize])

    const { data, isLoading, isError } = useQuery({
        queryKey: ['product-details', filter],
        queryFn: async () => {
            return await http.get(`agro/product/details/`, {
                params: filter
            })
        },
        placeholderData: keepPreviousData
    })

    if (isLoading) {
        return <Loader />
    }
    if (isError) {
        return <Error />
    }

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-10 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{title}</h2>
            </div>
            <div className='text-right mb-5 flex justify-between items-center'>
                <span>{t('total')} {data?.count} {t('ta')}</span>
            </div>

            {
                isLoading ? <Loader /> : (
                    <>
                        <Row gutter={[24, 24]}>
                            {
                                data?.results?.map((item, index) => {
                                    return (
                                        <Col xl={24} lg={24} md={24} sm={24} xs={24} key={index}>
                                            <div
                                                className='cursor-pointer'
                                                onClick={() => {
                                                    navigate(`/product/detail/${item?.id}?${queryString.stringify({ title })}`)
                                                }}
                                                style={{ borderBottom: '1px solid #00000030' }}
                                            >
                                                <div className='pb-5 flex items-center justify-start'>
                                                    <img src={item?.image} alt={item?.title} className='xl:w-[120px] lg:w-[100px] md:w-[90px] sm:w-[80px] w-[60px] xl:mr-5 mr-3' />
                                                    <h3 className='m-0 text-left'>{item?.title}</h3>
                                                    <div className='ml-auto arrow-icon' style={{ transform: 'rotate(180deg)' }}></div>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>

                        {
                            data?.results?.length === 0 ? <Empty /> : null
                        }

                        {
                            Array.isArray(data?.results) ? (
                                <div className='text-right mt-5'>
                                    <Pagination
                                        defaultCurrent={currentPage}
                                        current={currentPage}
                                        pageSize={pageSize}
                                        showSizeChanger={true}
                                        pageSizeOptions={[24, 36, 60]}
                                        onChange={(current, size) => {
                                            setCurrentPage(current)
                                            setPageSize(size)
                                        }}
                                        total={data?.count}
                                    />
                                </div>
                            ) : null
                        }
                    </>
                )
            }
            {isError ? <Error /> : null}
        </div>
    )
}

export default SecondInnerPage