import { Col } from 'antd'
import queryString from 'query-string'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import "./card.css"

const Card = (props) => {
    const { item, getData, url } = props
    const navigate = useNavigate()

    return (
        <Col
            xl={4}
            lg={6}
            md={8}
            sm={8}
            xs={12}
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
        >
            {
                url ? (
                    <a
                        href={url}
                        target='_blank'
                        rel="noreferrer"
                        className='cursor-pointer h-[100%]'
                    >
                        <div className='custom-card xl:px-[10px] xl:py-[50px] lg:py-[40px] md:py-[30px] sm:py-[20px] py-[10px]'>
                            <div
                                className='xl:w-[70px] xl:h-[70px] md:w-[60px] md:h-[60px] sm:w-[50px] sm:h-[50px] w-[40px] h-[40px] mx-auto mb-3'
                                style={{ maskImage: `url(${item?.image})`, backgroundColor: item?.color ?? 'black', maskRepeat: 'no-repeat', maskPosition: 'center', maskSize: 'contain' }}
                            ></div>
                            <h3 className='m-0 text-[#000000]'>{item?.title}</h3>
                        </div>
                    </a>
                ) : (
                    <div
                        onClick={() => {
                            item?.type === "download" ? getData(item?.id) : navigate(`/${item?.link}/${item?.id ? item?.id : ''}?${queryString.stringify({ title: item?.title })}`)
                        }}
                        className='cursor-pointer h-[100%]'
                    >
                        <div className='custom-card text-center xl:px-[10px] xl:py-[50px] lg:py-[40px] md:py-[30px] sm:py-[20px] py-[10px]'>
                            <div
                                className='xl:w-[70px] xl:h-[70px] md:w-[60px] md:h-[60px] sm:w-[50px] sm:h-[50px] w-[40px] h-[40px] mx-auto mb-3'
                                style={{ maskImage: `url(${item?.image})`, backgroundColor: item?.color ?? 'black', maskRepeat: 'no-repeat', maskPosition: 'center', maskSize: 'contain' }}
                            ></div>
                            <h3 className='m-0 text-[#000000]'>{item?.title}</h3>
                        </div>
                    </div>
                )
            }
        </Col>
    )
}

export default Card