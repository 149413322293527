import queryString from 'query-string'
import React, { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import http from '../../utils/helpers'
import Loader from '../loader'
import Error from '../error'
import { Col, Empty, Input, Pagination, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

const InnerProductDetail = () => {
    const params = useParams()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { title } = queryString.parse(window.location.search)

    const [search, setSearch] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)

    const filter = useMemo(() => {
        return {
            search,
            page: currentPage,
            page_size: pageSize,
            export_product: params.productId,
        }
    }, [params, search, currentPage, pageSize])

    const { data, isLoading, isError } = useQuery({
        queryKey: ['export-product-detail', filter],
        queryFn: async () => {
            return await http.get(`export/v2/products/detail/`, {
                params: filter
            })
        },
        placeholderData: keepPreviousData
    })

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-10 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{title}</h2>
            </div>

            <div className='text-right mb-5 flex justify-between items-center'>
                <span>{t('total')} {data?.count} {t('ta')}</span>
                <Input
                    placeholder={t('search')}
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    className='w-[250px] text-left'
                />
            </div>

            {
                isLoading ? <Loader /> : (
                    <>
                        <Row gutter={[24, 24]}>
                            {
                                data?.results?.map((item, index) => {
                                    return (
                                        <Col xl={6} lg={8} md={12} sm={24} xs={24} key={index}>
                                            <div className='custom-card p-4 h-[100%] flex flex-col'>
                                                <h4 className='text-[16px] font-bold'>{item?.export_product?.name_uz}</h4>
                                                <p><b>Eksport qilish mumkin bo'lgan {item?.export_countries?.length} davlatlar:</b></p>
                                                {
                                                    item?.export_countries?.map((c, i) => {
                                                        return (
                                                            <p key={i} className='cursor-pointer'>
                                                                <img src={`https://akk.karantin.uz/${c?.flag}`} alt='' className='w-[24px] mr-2' />
                                                                <u
                                                                    onClick={() => {
                                                                        navigate(`/export-countries/details/${c?.id}/product/${params.productId}?${queryString.stringify({ title: `${c?.title} / ${title}` })}`)
                                                                    }}
                                                                >{c?.title}</u>
                                                            </p>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>

                        {
                            data?.results?.length === 0 ? <Empty /> : null
                        }

                        {
                            Array.isArray(data?.results) ? (
                                <div className='text-right mt-5'>
                                    <Pagination
                                        defaultCurrent={currentPage}
                                        current={currentPage}
                                        pageSize={pageSize}
                                        showSizeChanger={true}
                                        pageSizeOptions={[20, 50, 100]}
                                        onChange={(current, size) => {
                                            setCurrentPage(current)
                                            setPageSize(size)
                                        }}
                                        total={data?.count}
                                    />
                                </div>
                            ) : null
                        }
                    </>
                )
            }

            {isError ? <Error /> : null}
        </div>
    )
}

export default InnerProductDetail