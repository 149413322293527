import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import './all-news.css'
import { Empty, Row } from 'antd'
import News from '../../components/news'
import { Pagination } from 'antd';
import Loader from '../loader'
import { useTranslation } from 'react-i18next'
import Error from '../error'
import http from '../../utils/helpers'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

const AllNews = () => {
    const { t } = useTranslation()

    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(12)

    const filter = useMemo(() => {
        return {
            page: currentPage,
            page_size: pageSize,
        }
    }, [currentPage, pageSize])

    const { data, isLoading, isError } = useQuery({
        queryKey: ['news-list', filter],
        queryFn: async () => {
            return await http.get(`news/list/`, {
                params: filter
            })
        },
        placeholderData: keepPreviousData
    })

    if (isLoading) {
        return <Loader />
    }
    if (isError) {
        return <Error />
    }

    return (
        <div className='all-news-page container p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='top-navigator'>
                <Link to="/">
                    <div>
                        {t('home')}
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M10 7L14 12L10 17" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </Link>
                <div className='active'>
                    {t('all-news')}
                </div>
            </div>

            <h1>{t('all-news')}</h1>
            {
                data?.news?.results?.length === 0 && !isLoading ? <Empty /> : null
            }
            {
                isLoading ? <Loader /> : (
                    <>
                        <Row
                            gutter={[24, 24]}
                        >
                            {
                                data?.news?.results?.map((item, index) => {
                                    return <News
                                        key={index}
                                        newsInfo={item}
                                        xl={8}
                                        lg={12}
                                        md={12}
                                        sm={24}
                                        xs={24}
                                    />
                                })
                            }
                        </Row>
                        {
                            data?.news?.results?.length > 0 ? (
                                <Pagination
                                    total={data?.news?.count}
                                    pageSize={pageSize}
                                    pageSizeOptions={[12, 24, 36, 48, 60]}
                                    current={currentPage}
                                    onChange={(a, b) => {
                                        setCurrentPage(a)
                                        setPageSize(b)
                                    }}
                                />
                            ) : null
                        }
                    </>
                )
            }
        </div>
    )
}

export default AllNews