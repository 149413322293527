import { Divider, Row } from 'antd'
import React from 'react'
import Card from '../../components/card'
import pestisidlarImage from "../../assets/images/ruhsat_etilgan.png"
import exportInspektorImage from "../../assets/images/export_inspector.png"
import uruglarImage from "../../assets/images/seeds.png"
import kochatlarImage from "../../assets/images/seelings.png"
import royxatMezonlariImage from "../../assets/images/royxat.png"
import elektornKutubxonaImage from "../../assets/images/books.png"
import tuproqTahlilImage from "../../assets/images/666666.png"
import tuproqUnumdorligiImage from "../../assets/images/111111.png"
import mineralOgitlarImage from "../../assets/images/555555.png"
import foydaliVideolarImage from "../../assets/images/play.png"
import yagonaDarchaImage from "../../assets/images/single_window.png"
import http from '../../utils/helpers'
import { useTranslation } from 'react-i18next'

const UsefulInformation = () => {
    const { t } = useTranslation()

    const foydaliMalumotlar = [
        {
            id: null,
            title: t('authorized_protective_equipment'),
            image: pestisidlarImage,
            color: 'brown',
            link: 'minerals'
        },
        {
            id: null,
            title: t('export_inspector'),
            image: exportInspektorImage,
            color: 'brown',
            link: 'inspektor'
        },
        {
            id: null,
            title: t('seeds'),
            image: uruglarImage,
            color: 'brown',
            link: 'seeds'
        },
        {
            id: null,
            title: t('seedlings'),
            image: kochatlarImage,
            color: 'brown',
            link: 'kochat'
        },
        {
            id: null,
            title: t('criteria_type'),
            image: royxatMezonlariImage,
            color: 'brown',
            link: "taqiqlangan-cheklangan-faol-moddalar"
        },
        {
            id: null,
            title: t('electronic_library'),
            image: elektornKutubxonaImage,
            color: 'brown',
            link: 'library',
        },
        {
            id: "soil",
            title: t('soil_analysis'),
            image: tuproqTahlilImage,
            color: 'brown',
            type: 'download'
        },
        {
            id: "productivity",
            title: t('increase_soil_fertility'),
            image: tuproqUnumdorligiImage,
            color: 'brown',
            type: 'download'
        },
        {
            id: "mineral",
            title: t('mineral_fertilizers'),
            image: mineralOgitlarImage,
            color: 'brown',
            type: 'download'
        },
        {
            id: null,
            title: t('useful_videos'),
            image: foydaliVideolarImage,
            color: 'brown',
            link: 'useful-videos'
        },
        {
            id: null,
            title: t('single_window_system'),
            image: yagonaDarchaImage,
            color: 'brown',
            link: 'single-window-videos'
        },
    ]

    const getData = async (id) => {
        try {
            const response = await http.get('/agro/analy/file', {
                params: {
                    type: id
                }
            })
            const href = response?.data[0] ? response?.data[0].file : null
            const link = document.createElement('a')
            link.target = "_blank"
            link.href = href
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <>
            <h1 className='mb-5'>{t('useful_information')}</h1>
            <Row
                gutter={[24, 24]}
            >
                {
                    foydaliMalumotlar?.map((item, index) => {
                        return <Card key={index} item={item} getData={getData} />
                    })
                }
            </Row>
            <br />
            <Divider />
        </>
    )
}

export default UsefulInformation