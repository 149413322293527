import React, { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import http from '../../utils/helpers'
import { Col, Empty, Input, Pagination, Row, Select } from 'antd'
import { makeOptions } from '../../utils'
import queryString from 'query-string'
import Loader from '../loader'
import Error from '../error'
import { useTranslation } from 'react-i18next'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

const InnerKochat = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const params = useParams()
    const { title } = queryString.parse(window.location.search)

    const url = params?.type === "local_sapling" ? "sapling_and_seed" : "import_sapling_and_seeds"
    const categoryUrl = params?.type === "local_sapling" ? "sapling_and_seed/plant_type/" : "import_sapling_and_seeds/product_names/"

    const [search, setSearch] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const [categoryId, setCategoryId] = useState(null)

    const filter = useMemo(() => {
        return {
            search,
            page: currentPage,
            page_size: pageSize,
            type_of: params.type,
            plant_type: categoryId,
            product_name: categoryId
        }
    }, [search, currentPage, pageSize, categoryId, params])

    const { data, isLoading, isError } = useQuery({
        queryKey: [url, filter],
        queryFn: async () => {
            return await http.get(`agro/${url}/`, {
                params: filter
            })
        },
        placeholderData: keepPreviousData
    })

    const { data: categories, isLoading: categoriesLoading } = useQuery({
        queryKey: [categoryUrl, params?.type],
        queryFn: async () => {
            return await http.get(`/agro/${categoryUrl}`, {
                params: {
                    type_of: params?.type,
                }
            })
        }
    })

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-5 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{title}</h2>
            </div>

            <div className='flex justify-between items-center mb-2 xl:flex-row lg:flex-row md:flex-row flex-col'>
                <span className='mb-2'>{t('total')} {data?.count} {t('ta')}</span>
                <div className='filter-wrap mb-2'>
                    <Input
                        placeholder={t('search')}
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    />
                    <Select
                        placeholder={t('search_via_product_name')}
                        loading={categoriesLoading}
                        value={categoryId}
                        options={
                            params?.type === "local_sapling" ? makeOptions(categories, 'name_uz')
                                : makeOptions(categories?.map(i => ({ id: i, title: i })), 'title')
                        }
                        onChange={(e) => setCategoryId(e)}
                        allowClear
                        onSearch={(e) => console.log(e)}
                        showSearch
                        filterOption={(input, option) =>
                            (option?.label || '').toLowerCase().includes(input.toLowerCase())
                        }
                    />
                </div>
            </div>

            {
                isLoading ? <Loader /> : (
                    <>
                        <Row gutter={[24, 24]}>
                            {
                                data?.results?.map((item, index) => {
                                    if (params?.type === "local_sapling") {
                                        return (
                                            <Col xl={8} lg={12} md={12} sm={24} xs={24} key={index}>
                                                <div className='custom-card p-4 h-[100%] flex flex-col '>
                                                    <p>{t('crop_type')} <b className='text-[#147461]'>{item?.plant_type?.name_uz}</b></p>
                                                    <p>{t('recommended_area')} <b>{item?.reccomendation_region_uz}</b></p>
                                                    <p>{t('for_greenhouse')} <b>{item?.for_where_uz}</b></p>
                                                    <p>{t('sort')} <b>{item?.sort_uz}</b></p>
                                                    <p>{t('name_and_address')} <b>{item?.company_name}</b></p>
                                                    <p>{t('head_enterprise')} <b>{item?.company_owner_uz}</b></p>
                                                    <p className='m-0'>{t('phone_number_enterprise')} <b><a href={`tel:${item?.company_owner_phone}`}>{item?.company_owner_phone}</a></b></p>
                                                </div>
                                            </Col>
                                        )
                                    } else {
                                        return (
                                            <Col xl={8} lg={12} md={12} sm={24} xs={24} key={index}>
                                                <div className='custom-card p-4 h-[100%] flex flex-col '>
                                                    <p>{t('import_countri')} <b>{item?.company_name_uz}</b></p>
                                                    <p>{t('import_phone')} <b><a href={`tel:${item?.phone_number}`}>{item?.phone_number}</a></b></p>
                                                    <p>{t('sort_name_sapling')} <b className='text-[#147461]'>{item?.product_name_uz}</b></p>
                                                    <p>{t('sort')} <b>{item?.sort_type}</b></p>
                                                    <p>{t('import_country')} <b>{item?.country_uz}</b></p>
                                                    <p className='m-0'>{t('importer_com')} <b>{item?.address_uz}</b></p>
                                                </div>
                                            </Col>
                                        )
                                    }
                                })
                            }
                        </Row>

                        {
                            data?.results?.length === 0 ? <Empty /> : null
                        }

                        {
                            Array.isArray(data?.results) ? (
                                <div className='text-right mt-5'>
                                    <Pagination
                                        defaultCurrent={currentPage}
                                        current={currentPage}
                                        pageSize={pageSize}
                                        showSizeChanger={true}
                                        pageSizeOptions={[20, 50, 100]}
                                        onChange={(current, size) => {
                                            setCurrentPage(current)
                                            setPageSize(size)
                                        }}
                                        total={data?.count}
                                    />
                                </div>
                            ) : null
                        }
                    </>
                )
            }

            {isError ? <Error /> : null}
        </div>
    )
}

export default InnerKochat