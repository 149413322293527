import { Divider, Row } from 'antd'
import React from 'react'
import Card from '../../components/card'
import tozaHududImage from "../../assets/images/yashil.png"
import zararlanganHududImage from "../../assets/images/qizil.png"
import { useTranslation } from 'react-i18next'

const Import = () => {
    const { t } = useTranslation()

    const imports = [
        {
            id: null,
            title: t('phytosanitary_clean_areas'),
            image: tozaHududImage,
            color: 'green',
            link: 'zone-countries'
        },
        {
            id: null,
            title: t('temporarily_banned_businesses'),
            image: zararlanganHududImage,
            color: 'red',
            link: 'zone-countries'
        },
    ]

    return (
        <>
            <h1 className='mb-5'>{t('import')}</h1>
            <Row
                gutter={[24, 24]}
            >
                {
                    imports?.map((item, index) => {
                        return <Card key={index} item={item} />
                    })
                }
            </Row>
            <br />
            <Divider />
        </>
    )
}

export default Import