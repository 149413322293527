import React from 'react'
import Protection from './protection'
import ServicesTypes from './services-types'
import UsefulInformation from './useful-information'
import Eksport from './eksport'
import Import from './import'
import HomeNews from '../news'
import OtherServices from './other-services'

const Home = () => {
    return (
        <div className='p-5'>
            <HomeNews />
            <Protection />
            <ServicesTypes />
            <UsefulInformation />
            <Eksport />
            <Import />
            <OtherServices />
        </div>
    )
}

export default Home