import { Divider, Row } from 'antd'
import React, { useState } from 'react'
import Card from '../../components/card'
import callImage from "../../assets/images/call.png"
import checkImage from "../../assets/images/check_sertificat.png"
import hujjatImage from "../../assets/images/hujjat.png"
import { useTranslation } from 'react-i18next'
import MobileAppInfoModal from '../../modals/mobile-app-info-modal'

const OtherServices = () => {
    const { t } = useTranslation()

    const [isOpenModal, setIsOpenModal] = useState(false)

    const eksport = [
        {
            id: null,
            title: t('call_the_inspector'),
            image: callImage,
            color: 'brown',
            link: 'call-inspektor',
            type: 'download'
        },
        {
            id: null,
            title: t('check_certificate'),
            image: checkImage,
            color: 'brown',
            link: 'check-sertificate'
        },
        {
            id: null,
            title: t('normative_legal_documents'),
            image: hujjatImage,
            color: 'brown',
            link: 'documents'
        },
    ]

    const getData = () => {
        setIsOpenModal(true)
    }

    return (
        <>
            {
                isOpenModal ? (
                    <MobileAppInfoModal
                        isOpenModal={isOpenModal}
                        setIsOpenModal={setIsOpenModal}
                    />
                ) : null
            }

            <h1 className='mb-5'>{t('other_service')}</h1>
            <Row
                gutter={[24, 24]}
            >
                {
                    eksport?.map((item, index) => {
                        return <Card key={index} item={item} url={item?.url} getData={getData} />
                    })
                }
            </Row>
            <br />
            <Divider />
        </>
    )
}

export default OtherServices