import { Modal } from 'antd'
import React from 'react'
import alertImg from '../../assets/images/alert.png'
import appStoreImg from '../../assets/images/app-store.png'
import androidImg from '../../assets/images/android.png'
import { useTranslation } from 'react-i18next'

const MobileAppInfoModal = (props) => {
    const { isOpenModal, setIsOpenModal } = props
    const { t } = useTranslation()

    return (
        <Modal
            open={isOpenModal}
            onCancel={() => setIsOpenModal(false)}
            footer={null}
            width={800}
            centered
            className='p-5'
        >
            <div className='text-center'>
                <img src={alertImg} alt='' className='w-[50px]' />
            </div>
            <div>
                <h5 className='text-center mx-auto text-[18px] my-5 w-[80%]'>{t('mobile-info')}</h5>
                <div className='flex justify-center'>
                    <a href='https://apps.apple.com/uz/app/agrokomakchi/id1658946098' target='_blank' rel="noreferrer">
                        <div className='info-card mx-2'>
                            <img src={appStoreImg} alt='' className='w-[40px] h-[40px]' />
                        </div>
                    </a>
                    <a href='https://play.google.com/store/apps/details?id=uz.karantin.agrokomakchi' target='_blank' rel="noreferrer">
                        <div className='info-card mx-2'>
                            <img src={androidImg} alt='' className='w-[40px] h-[40px]' />
                        </div>
                    </a>
                </div>
            </div>
        </Modal>
    )
}

export default MobileAppInfoModal