import { Divider, Row } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import zararkunandaImage from "../../assets/images/fly.png"
import kasallikImage from "../../assets/images/virus.png"
import begonaUtImage from "../../assets/images/begona_otlar.png"
import uygunKurashImage from "../../assets/images/ipm.png"
import Card from '../../components/card'

const Protection = () => {
    const { t } = useTranslation()

    const usimlikHimoyasi = [
        {
            id: "ZARARKUNANDA",
            title: t('pests'),
            image: zararkunandaImage,
            color: 'red',
            link: 'products'
        },
        {
            id: "KASALLIK",
            title: t('diseases'),
            image: kasallikImage,
            color: 'red',
            link: 'products'
        },
        {
            id: "BEGONA_UTLAR",
            title: t('weeds'),
            image: begonaUtImage,
            color: 'red',
            link: 'products'
        },
        {
            id: null,
            title: t('harmonized_protection'),
            image: uygunKurashImage,
            color: 'red',
            link: 'ipm',
        },
    ]
    return (
        <>
            <h1 className='mb-5'>{t('protection')}</h1>
            <Row
                gutter={[24, 24]}
            >
                {
                    usimlikHimoyasi?.map((item, index) => {
                        return <Card key={index} item={item} />
                    })
                }
            </Row>
            <br />
            <Divider />
        </>
    )
}

export default Protection