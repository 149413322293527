import React, { useEffect } from 'react';

const VisitorsStatistics = () => {
  useEffect(() => {
    const scriptContent = `
      top_js = "1.0";
      top_r =
        "id=47660&r=" +
        escape(document.referrer) +
        "&pg=" +
        escape(window.location.href);
      document.cookie = "smart_top=1; path=/";
      top_r += "&c=" + (document.cookie ? "Y" : "N");

      top_js = "1.1";
      top_r += "&j=" + (navigator.javaEnabled() ? "Y" : "N");

      top_js = "1.2";
      top_r +=
        "&wh=" +
        screen.width +
        "x" +
        screen.height +
        "&px=" +
        (navigator.appName.substring(0, 3) == "Mic"
          ? screen.colorDepth
          : screen.pixelDepth);

      top_js = "1.3";

      top_rat = "&col=7DC53B&t=ffffff&p=DD444E";
      top_r += "&js=" + top_js + "";
      
      var img = document.createElement('img');
      img.src = "https://cnt0.www.uz/counter/collect?" + top_r + top_rat;
      img.width = 88;
      img.height = 31;
      img.border = 0;
      img.alt = "Топ рейтинг www.uz";
      document.getElementById('visitor-statistics').appendChild(img);
    `;

    const scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.innerHTML = scriptContent;
    document.querySelector('#visitor-statistics').appendChild(scriptElement);
  }, []);

  return <a href='https://www.uz/ru/res/visitor/index?id=47660' target='_blank' rel="noreferrer" >
    <div className='mt-4' id="visitor-statistics"></div>
  </a>;
};

export default VisitorsStatistics;
