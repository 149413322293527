// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/certificate.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.serticat-bg {
  width: 150px;
  height: 150px;
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  mask-size: cover;
  mask-repeat: no-repeat;
  mask-position: center;
  background-color: #147461;
  margin: auto;
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/check-sertificate/index.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mDAAsD;EACtD,gBAAgB;EAChB,sBAAsB;EACtB,qBAAqB;EACrB,yBAAyB;EACzB,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".serticat-bg {\n  width: 150px;\n  height: 150px;\n  mask-image: url(\"../../assets/images/certificate.png\");\n  mask-size: cover;\n  mask-repeat: no-repeat;\n  mask-position: center;\n  background-color: #147461;\n  margin: auto;\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
