import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie"
import { writeHeaders } from "../utils/helpers";

const accessToken = Cookies.get("access_token") ?? null

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        accessToken,
        isAuth: !!accessToken
    },
    reducers: {
        userLogin: (state, action) => {
            state.accessToken = action.payload.token
            state.isAuth = true
            Cookies.set("access_token", action.payload.token, { expires: 30 })
            writeHeaders(action.payload.token)
        },
        userLogout: (state) => {
            state.accessToken = null
            state.isAuth = false
            Cookies.remove("access_token")
        }
    }
})

export const { userLogin, userLogout } = authSlice.actions
export default authSlice.reducer