import React from 'react'
import './news.css'
import { Col } from 'antd'
import { Link } from 'react-router-dom'

const News = (props) => {
    const { xl, lg, md, sm, xs, newsInfo } = props

    return (
        <Col
            xl={xl}
            lg={lg}
            md={md}
            sm={sm}
            xs={xs}
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
        >
            <Link to={`/news/${newsInfo?.id}`}>
                <div className='one-news' style={{ backgroundImage: `url(${newsInfo?.image})` }}>
                    <div className='bottom'>
                        <h2>{newsInfo?.title?.slice(0, 50)}...</h2>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                <path d="M2 6.5V12.5C2 13.9728 3.19391 15.1667 4.66667 15.1667H11.3333C12.8061 15.1667 14 13.9728 14 12.5V6.5M2 6.5V5.5C2 4.02724 3.19391 2.83333 4.66667 2.83333H11.3333C12.8061 2.83333 14 4.02724 14 5.5V6.5M2 6.5H14M10.6667 1.83333V3.83333M5.33333 1.83333V3.83333" stroke="#888888" strokeLinecap="round" />
                            </svg>
                            <p>{newsInfo?.created_at}</p>
                        </div>
                    </div>
                </div>
            </Link>
        </Col>
    )
}

export default News