import React, { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Col, Empty, Input, Pagination, Row, Select } from 'antd'
import Loader from '../loader'
import Error from '../error'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'
import http from '../../utils/helpers'
import { makeOptions } from '../../utils'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

const InnerForeignImportersAgents = () => {
    const navigate = useNavigate()
    const params = useParams()
    const { t } = useTranslation()
    const { title } = queryString.parse(window.location.search)

    const [search, setSearch] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const [categoryId, setCategoryId] = useState(null)

    const filter = useMemo(() => {
        return {
            search,
            page: currentPage,
            page_size: pageSize,
            country: params.countryId,
            category: categoryId,
        }
    }, [search, currentPage, pageSize, params, categoryId])

    const { data, isLoading, isError } = useQuery({
        queryKey: ['export-importers', filter],
        queryFn: async () => {
            return await http.get(`/export/importers/`, {
                params: filter,
            })
        },
        placeholderData: keepPreviousData
    })

    const { data: categories, isLoading: categoriesLoading } = useQuery({
        queryKey: ['importers-type',],
        queryFn: async () => {
            return await http.get(`/export/importers/type/`)
        }
    })

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-5 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{title}</h2>
            </div>

            <div className='flex justify-between items-center mb-2 xl:flex-row lg:flex-row md:flex-row flex-col'>
                <span className='mb-2'>{t('total')} {data?.count} {t('ta')}</span>
                <div className='text-right mb-2 filter-wrap'>
                    <Input
                        placeholder={t('search')}
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        className='w-[250px] text-left'
                    />
                    <Select
                        className='w-[250px] text-left'
                        placeholder={t('categories')}
                        loading={categoriesLoading}
                        value={categoryId}
                        options={makeOptions(categories, 'name')}
                        onChange={(e) => setCategoryId(e)}
                        allowClear
                    />
                </div>
            </div>

            {
                isLoading ? <Loader /> : (
                    <>
                        <Row gutter={[24, 24]}>
                            {
                                data?.results?.map((item, index) => {
                                    return (
                                        <Col xl={6} lg={8} md={12} sm={24} xs={24} key={index}>
                                            <div className='custom-card p-4 h-[100%] cursor-pointer'>
                                                <h4 className='font-bold mb-3 text-center'>{item?.name}</h4>
                                                <div className='text-left'>
                                                    <p>{t('import_type')}: <b>{item?.importer_type?.name}</b></p>
                                                    <p>{t('email')}: <a href={`mailto:${item?.email}`}>{item?.email ?? '-'}</a></p>
                                                    <p>{t('phone')}<a className='mx-2' href={`tel:${item?.phone}`}>{item?.phone}</a></p>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>

                        {
                            data?.results?.length === 0 ? <Empty /> : null
                        }

                        {
                            Array.isArray(data?.results) ? (
                                <div className='text-right mt-5'>
                                    <Pagination
                                        defaultCurrent={currentPage}
                                        current={currentPage}
                                        pageSize={pageSize}
                                        showSizeChanger={true}
                                        pageSizeOptions={[20, 50, 100]}
                                        onChange={(current, size) => {
                                            setCurrentPage(current)
                                            setPageSize(size)
                                        }}
                                        total={data?.count}
                                    />
                                </div>
                            ) : null
                        }
                    </>
                )
            }
            {isError ? <Error /> : null}
        </div>
    )
}

export default InnerForeignImportersAgents