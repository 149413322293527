import { Spin } from 'antd'
import React from 'react'

const Loader = () => {
    return (
        <div className='w-[100%] h-[100vh] flex items-center justify-center'>
            <Spin size='large' />
        </div>
    )
}

export default Loader