import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { Col, Empty, Row } from 'antd'
import Loader from '../loader'
import Error from '../error'
import { useTranslation } from 'react-i18next'
import http from '../../utils/helpers'

const Uzairways = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { data, isLoading, isError } = useQuery({
        queryKey: ['air_ways_logistics'],
        queryFn: async () => {
            return await http.get(`/common/web/links/?type=air_ways_logistics`)
        },
    })

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-5 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{t('uzairways')}</h2>
            </div>

            <div className='text-right mb-5 flex justify-between items-center'>
                <span>{t('total')} {data?.length} {t('ta')}</span>
            </div>

            {
                isLoading ? <Loader /> : (
                    <>
                        <Row gutter={[24, 24]}>
                            {
                                data?.map((item, index) => {
                                    return (
                                        <Col xl={4} lg={6} md={8} sm={8} xs={12} key={index}>
                                            <a href={item?.link} target='_blank' rel="noreferrer">
                                                <div className='custom-card p-4 h-[100%] flex items-center flex-col cursor-pointer'>
                                                    <img src={item?.logo} alt={item?.title} className='w-[70px] mb-2' />
                                                    <h3 className='m-0 text-[#147461]'>{item?.title}</h3>
                                                </div>
                                            </a>
                                        </Col>
                                    )
                                })
                            }
                        </Row>

                        {
                            data?.data?.length === 0 ? <Empty /> : null
                        }
                    </>
                )
            }
            {isError ? <Error /> : null}
        </div>
    )
}

export default Uzairways