import { Button, Result } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

const Error = () => {
    return (
        <div className='w-[100%] h-[100vh] flex items-center justify-center'>
            <Result
                status="500"
                title="500"
                subTitle="Kechirasiz, qandaydir muammo sodir bo'ldi."
                extra={
                    <Link to='/'>
                        <Button type="primary">Bosh sahifaga qaytish</Button>
                    </Link>
                }
            />
        </div>
    )
}

export default Error