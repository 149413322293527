import axios from "axios";
import Cookies from "js-cookie";
import { BASE_URL } from "../constants";

const initialLang = Cookies.get('i18next')

const token = Cookies.get("access_token")

const http = axios.create({
    baseURL: BASE_URL,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Accept-Language': initialLang,
    }
})

http.interceptors.request.use(
    config => {
        if (token) {
            config.headers = {
                'Authorization': `Bearer ${token}`,
                'Accept-Language': initialLang,
            }
            return config
        } else {
            return config
        }
    }
)

http.interceptors.response.use(
    response => response.data,
    error => {
        if (error?.response?.status === 401) {
            // toast.warning('Token has expired, Please login!')
            Cookies.remove("access_token")
            window.location.reload()
        }
        return Promise.reject(error)
    }
)

export function writeHeaders(t) {
    http.defaults.headers.common['Authorization'] = `Bearer ${t}`
}

export default http