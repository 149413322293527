import React from 'react'
import { useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import parse from "html-react-parser"

const InnerSingleWindowVideo = () => {
    const navigate = useNavigate()
    const { title, video, content, video_type } = queryString.parse(window.location.search)

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-5 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{title}</h2>
            </div>

            {
                video_type === "youtube" ? (
                    <iframe
                        src={`https://www.youtube.com/embed/${video}`}
                        className='w-[100%] h-[40vh]'
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                ) : (
                    <video
                        controls
                        className='w-[100%] h-[40vh]'
                    >
                        <source src={video} type='video/mp4' />
                    </video>
                )
            }

            <div className='mt-5'>
                {parse(content)}
            </div>
        </div>
    )
}

export default InnerSingleWindowVideo