import React, { useMemo, lazy, Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import http from '../../utils/helpers'
import { Spin } from 'antd'
import { Row } from 'antd'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Pagination } from 'swiper/modules'
import { Link } from 'react-router-dom'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

const News = lazy(() => import('../../components/news'));

const HomeNews = () => {
    const { t } = useTranslation()

    const filter = useMemo(() => {
        return {
            page: 1,
            page_size: 6,
        }
    }, [])

    const { data, isLoading, isError } = useQuery({
        queryKey: ['news-list', filter],
        queryFn: async () => {
            return await http.get(`news/list/`, {
                params: filter
            })
        },
        placeholderData: keepPreviousData
    })

    if (isLoading) {
        return <div className='text-center p-5'><Spin className='m-auto' size='large' /></div>
    }
    if (isError) {
        return <></>
    }

    return (
        <>
            <div className='flex mb-5 items-center justify-between'>
                <h1>{t('news')}</h1>
                <Link to='/news' className='text-[blue]'>{t('all')}</Link>
            </div>
            <Row
                gutter={[24, 24]}
            >
                <Swiper
                    breakpoints={{
                        768: {
                            slidesPerView: 2,
                        },
                        1200: {
                            slidesPerView: 2,
                        },
                        1400: {
                            slidesPerView: 3,
                        },
                    }}
                    freeMode={true}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[FreeMode, Pagination]}
                >
                    {
                        data?.news?.results?.map((item, index) => {
                            return (
                                <SwiperSlide
                                    key={index}
                                >
                                    <Suspense fallback={<Spin />}>
                                        <News
                                            newsInfo={item}
                                            xl={24}
                                            lg={24}
                                            md={24}
                                            sm={24}
                                            xs={24}
                                        />
                                    </Suspense>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </Row>
        </>
    )
}

export default HomeNews