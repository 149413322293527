import { Button, Form, Input, Select } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'
import './index.css'
import CheckedCertificateDetailModal from '../../modals/checked-certificate-detail-modal'
import { useMutation } from '@tanstack/react-query'

const CheckSertificate = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [isOpenModal, setIsOpenModal] = useState(false)

    const { mutate: sumbitCertificate, isPending, data: checkedData } = useMutation({
        mutationKey: ['check-sertificate'],
        mutationFn: async (data) => {
            const response = await axios.get(`https://efito.uz/check_certificate/${data?.sertificatType}/${data?.seriaNumber}`)
            return response?.data
        },
        onSuccess: () => {
            setIsOpenModal(true)
        },
        onError: () => {
            toast.error(t('error'))
        }
    }
    )

    const onFinish = (values) => {
        sumbitCertificate(values)
    }

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-5 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{t('check_certificate')}</h2>
            </div>

            {
                isOpenModal ? (
                    <CheckedCertificateDetailModal
                        isOpenModal={isOpenModal}
                        setIsOpenModal={setIsOpenModal}
                        data={checkedData}
                    />
                ) : null
            }

            <div className='serticat-bg'></div>

            <Form
                name="check-certificate"
                style={{
                    maxWidth: 600,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off"
                layout='vertical'
                className='mx-auto mt-10'
            >
                <Form.Item
                    label={t('quarantine_permit')}
                    name="sertificatType"
                    rules={[
                        {
                            required: true,
                            message: 'Please select!',
                        },
                    ]}
                >
                    <Select
                        placeholder={t('select_the_certificate')}
                        options={[
                            { value: 'export_fss', label: t('phytosanitary_certificate') },
                            { value: 'ikr', label: t('quarantine_permit') },
                            { value: 'fumigation', label: t('decontamination_certificate') },
                            { value: 'akd', label: t('quarantine_inspection_document') },
                            { value: 'local_fss', label: t('internal_phytosanitary_certificate') },
                            { value: 'lab_protocol', label: t('expert_opinion') },
                            { value: 'ppp_registration_protocol', label: t('testimony') },
                        ]}
                        size='large'
                        allowClear
                    />
                </Form.Item>
                <Form.Item
                    label={t('enter_the_blank_series_or_number')}
                    name="seriaNumber"
                    rules={[
                        {
                            required: true,
                            message: 'Please input!',
                        },
                    ]}
                >
                    <Input size='large' placeholder={t('enter_the_blank_series_or_number')} />
                </Form.Item>

                <Form.Item>
                    <Button
                        className='w-[100%]'
                        size='large'
                        type="primary"
                        htmlType="submit"
                        loading={isPending}
                    >
                        {t('check')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default CheckSertificate