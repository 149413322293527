import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import http from '../../utils/helpers'
import Loader from '../loader'
import Error from '../error'
import { Segmented } from 'antd'
import parse from "html-react-parser"
import { useTranslation } from 'react-i18next'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

const FeromonTutqich = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { data, isLoading, isError } = useQuery({
        queryKey: ['agro-feramon'],
        queryFn: async () => {
            return await http.get(`agro/feramon/`)
        },
        placeholderData: keepPreviousData
    })

    const [activeTab, setActiveTab] = useState(null)

    useEffect(() => {
        setActiveTab(data?.data?.feramons[0]?.name)
    }, [data])

    if (isLoading) {
        return <Loader />
    }
    if (isError) {
        return <Error />
    }

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-5 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{t('feromon')}</h2>
            </div>

            <div style={{ overflowX: 'auto' }}>
                <Segmented
                    defaultValue="center"
                    style={{
                        marginBottom: 16,
                    }}
                    onChange={(value) => setActiveTab(value)}
                    value={activeTab}
                    options={data?.data?.feramons?.map(item => item.name)}
                />
            </div>

            {
                data?.data?.feramons?.filter((item) => item?.name === activeTab)?.map((item, index) => {
                    return (
                        <div key={index}>
                            <div className='mb-3 flex justify-center' style={{ overflowX: 'auto' }} key={index}>
                                <PhotoProvider>
                                    {
                                        item?.images?.map((img, i) => {
                                            return (
                                                <div key={i} >
                                                    <PhotoView key={i} src={img?.image}>
                                                        <img src={img?.image} alt="" className='block m-2 w-[350px] h-[250px]' style={{ borderRadius: '12px' }} />
                                                    </PhotoView>
                                                </div>
                                            )
                                        })
                                    }
                                </PhotoProvider>
                            </div>
                            <div>{parse(item?.description)}</div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default FeromonTutqich