import { Col, Row } from 'antd'
import queryString from 'query-string'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import royxatMezonlariImage from "../../assets/images/royxat.png"
import { useTranslation } from 'react-i18next'

const TaqiqlanganModdalar = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const items = [
        {
            title: t('restricted_substances'),
            image: royxatMezonlariImage,
            link: 'https://karantin.uz/uploads/menu/taqiqlangan_kimyoviy_modalar.pdf',
            type: "download"
        },
        {
            title: t('registration_criteria'),
            image: royxatMezonlariImage,
            link: 'mezonlar',
            type: "link"
        },
    ]

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-5 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{t('criteria_type')}</h2>
            </div>

            <div className='text-right mb-5 flex justify-between items-center'>
                <span>{t('total')} {items?.length} {t('ta')}</span>
            </div>

            <Row gutter={[24, 24]}>
                {
                    items?.map((item, index) => {
                        return (
                            <Col xl={6} lg={6} md={8} sm={12} xs={24} key={index}>
                                {
                                    item.type === "download" ? (
                                        <a
                                            href={item.link}
                                            target='_blank'
                                            rel="noreferrer"
                                            className='cursor-pointer h-[100%]'
                                        >
                                            <div className='cursor-pointer'>
                                                <div className='custom-card p-4 h-[100%] flex items-center flex-col'>
                                                    <div
                                                        className='w-[60px] h-[70px] mx-auto mb-3'
                                                        style={{ maskImage: `url(${item?.image})`, backgroundColor: index === 0 ? "red" : "blue", maskRepeat: 'no-repeat', maskPosition: 'center', maskSize: 'contain' }}
                                                    ></div>
                                                    <h3 className='m-0 text-[20px] text-[#000000]'>{item?.title}</h3>
                                                </div>
                                            </div>
                                        </a>
                                    ) : (
                                        <div
                                            className='cursor-pointer'
                                            onClick={() => {
                                                navigate(`${item?.link}/?${queryString.stringify({ title: item?.title })}`)
                                            }}
                                        >
                                            <div className='custom-card p-4 h-[100%] flex items-center flex-col'>
                                                <div
                                                    className='w-[60px] h-[70px] mx-auto mb-3'
                                                    style={{ maskImage: `url(${item?.image})`, backgroundColor: index === 0 ? "red" : "blue", maskRepeat: 'no-repeat', maskPosition: 'center', maskSize: 'contain' }}
                                                ></div>
                                                <h3 className='m-0 text-[20px] text-[#000000]'>{item?.title}</h3>
                                            </div>
                                        </div>
                                    )
                                }
                            </Col>
                        )
                    })
                }
            </Row>
        </div >
    )
}

export default TaqiqlanganModdalar