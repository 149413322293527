import React, { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Col, DatePicker, Empty, Row } from 'antd'
import Loader from '../loader'
import Error from '../error'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import dayjs from 'dayjs'
import { get } from 'lodash'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

const { RangePicker } = DatePicker

const InnerAbroadPriceDetail = () => {
    const navigate = useNavigate()
    const params = useParams()
    const { t } = useTranslation()
    const { title } = queryString.parse(window.location.search)

    const [dateStrings, setDateStrings] = useState(['', ''])
    const [dateRangeValue, setDateRangeValue] = useState(undefined)
    const onChangeRange = (dates, dateStrings) => {
        setDateStrings(dateStrings)
        setDateRangeValue(dates)
    }

    const filter = useMemo(() => {
        return {
            from_date: dateStrings[0],
            to_date: dateStrings[1]
        }
    }, [dateStrings])

    const { data, isLoading, isError } = useQuery({
        queryKey: ['price-common-countries', { ...filter, countryId: params.countryId, productId: params.productId }],
        queryFn: async () => {
            return await axios.get(`https://narx.karantin.uz/api/v1/export/app/products/prices/?country_id=${params.countryId}&product_id=${params.productId}`, {
                params: filter,
                headers: {
                    'Authorization': `Token ${window.localStorage.getItem(`price-token`)}`
                }
            })
        },
        placeholderData: keepPreviousData
    })

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-5 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{title}</h2>
            </div>

            <div className='text-right mb-5 flex justify-between items-center'>
                <span>{t('total')} {data?.data?.count} {t('ta')}</span>
                <RangePicker
                    onChange={onChangeRange}
                    value={
                        dateRangeValue
                            ? [dayjs(get(dateRangeValue, '[0]', '')), dayjs(get(dateRangeValue, '[1]', ''))]
                            : undefined
                    }
                />
            </div>

            {
                isLoading ? <Loader /> : (
                    <>
                        <Row gutter={[24, 24]}>
                            {
                                data?.data?.results?.map((item, index) => {
                                    return (
                                        <Col xl={6} lg={8} md={12} sm={24} xs={24} key={index}>
                                            <div className='custom-card p-4 h-[100%]'>
                                                <p>{t('made_country')} <b>{item?.where_come_from}</b></p>
                                                <p>{t('min')} <b>{item?.market_min_price} {item?.market_currency_type}</b></p>
                                                <p>{t('max')} <b>{item?.market_max_price} {item?.market_currency_type}</b></p>
                                                <p>{t('packaging')} <b>{item?.product_packaging_name} {item?.product_packaging_size} {item?.product_packaging_unit}</b></p>
                                                <p>{t('entering_date')} <b>{item?.modified_at}</b></p>
                                                <p>{t('responsible_employee')} <b>{item?.user_data?.full_name}</b></p>
                                                <p className='m-0'>{t('employee_email')} <b><a href={`mailto:${item?.user_data?.email}`}>{item?.user_data?.email}</a></b></p>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>

                        {
                            data?.data?.count === 0 ? <Empty /> : null
                        }
                    </>
                )
            }
            {isError ? <Error /> : null}
        </div>
    )
}

export default InnerAbroadPriceDetail