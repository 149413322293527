import React from 'react'
import http from '../../utils/helpers'
import { Link, useParams } from 'react-router-dom'
import Loader from '../loader'
import parse from "html-react-parser"
import './news-detail.css'
import { Empty, Row } from 'antd'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Pagination } from 'swiper/modules'
import News from '../../components/news'
import { useTranslation } from 'react-i18next'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import { useQuery } from '@tanstack/react-query'

const NewsById = () => {
    const { t } = useTranslation()
    const params = useParams()
    const id = params.id

    const { data, isLoading } = useQuery({
        queryKey: ['news-detail', id],
        queryFn: async () => {
            let response = await http.get(`news/detail/${id}/`)
            response.content = response?.content?.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/')
            return response
        }
    })

    const { data: otherNews, isLoading: isOtherNewsLoading } = useQuery({
        queryKey: ['news-other', id],
        queryFn: async () => {
            return await http.get(`news/list/?is_random=${true}`)
        }
    })

    return (
        <div className='news-detail-page mx-auto p-5 container' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='top-navigator'>
                <Link to="/">
                    <div>
                        {t('home')}
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M10 7L14 12L10 17" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </Link>
                <Link to="/news">
                    <div>
                        {t('all-news')}
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M10 7L14 12L10 17" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </Link>
                <div className='active'>
                    {data?.title}
                </div>
            </div>

            {
                isLoading ? <Loader /> : (
                    <>
                        <div className='news-content box'>
                            <Swiper
                                freeMode={true}
                                pagination={{
                                    clickable: true,
                                }}
                                modules={[FreeMode, Pagination]}
                            >
                                <div className='mb-3 flex' style={{ overflowX: 'auto' }}>
                                    <PhotoProvider>
                                        {
                                            data?.images?.map((item, index) => {
                                                return (
                                                    <PhotoView key={index} src={item.image}>
                                                        <img src={item?.image} alt="" className='block m-2 w-[350px] h-[250px]' style={{ borderRadius: '12px', objectFit: "cover" }} />
                                                    </PhotoView>
                                                )
                                            })
                                        }
                                    </PhotoProvider>
                                </div>
                            </Swiper>
                            <div className='top'>
                                <h1>{data?.title}</h1>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M3 9V18C3 20.2091 4.79086 22 7 22H17C19.2091 22 21 20.2091 21 18V9M3 9V7.5C3 5.29086 4.79086 3.5 7 3.5H17C19.2091 3.5 21 5.29086 21 7.5V9M3 9H21M16 2V5M8 2V5" stroke="#888888" strokeWidth="1.5" strokeLinecap="round" />
                                    </svg>
                                    <span>{data?.created_at}</span>
                                </div>
                            </div>
                            <div>{data?.content ? parse(data?.content) : null}</div>
                        </div>

                        <section>
                            <h3>{t('other_news')}</h3>
                            {!isOtherNewsLoading && otherNews?.news?.results?.length === 0 ? <Empty /> : null}
                            {
                                isOtherNewsLoading ? <Loader /> : (
                                    <Row
                                        gutter={[24, 24]}
                                    >
                                        <Swiper
                                            breakpoints={{
                                                768: {
                                                    slidesPerView: 2,
                                                },
                                                1600: {
                                                    slidesPerView: 3,
                                                },
                                            }}
                                            freeMode={true}
                                            pagination={{
                                                clickable: true,
                                            }}
                                            modules={[FreeMode, Pagination]}
                                        >
                                            {
                                                otherNews?.news?.results?.map((item, index) => {
                                                    return (
                                                        <SwiperSlide
                                                            key={index}
                                                        >
                                                            <News
                                                                newsInfo={item}
                                                                xl={24}
                                                                lg={24}
                                                                md={24}
                                                                sm={24}
                                                                xs={24}
                                                            />
                                                        </SwiperSlide>
                                                    )
                                                })
                                            }
                                        </Swiper>
                                    </Row>
                                )
                            }
                        </section>
                    </>
                )
            }
        </div >
    )
}

export default NewsById