import queryString from 'query-string'
import React, { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import http from '../../utils/helpers'
import Loader from '../loader'
import Error from '../error'
import { Col, Empty, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

const InnerZoneCountryDetail = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { title, type } = queryString.parse(window.location.search)


    const filter = useMemo(() => {
        return {
            import_country: params.countryId,
            id: params.id,
        }
    }, [params,])

    const { data, isLoading, isError } = useQuery({
        queryKey: ['import-zone-products-detail', filter],
        queryFn: async () => {
            return await http.get(`import/zone-products/${params?.id}/`, {
                params: filter
            })
        },
        placeholderData: keepPreviousData
    })

    if (isLoading) {
        return <Loader />
    }
    if (isError) {
        return <Error />
    }

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-10 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{title}</h2>
            </div>

            <div className='text-right mb-5 flex justify-between items-center'>
                <span>{t('total')} {data?.length} {t('ta')}</span>
            </div>

            <h3 className='mb-5'>{data?.title}</h3>
            {
                type === 'red' ? (
                    <h4 className='text-center text-[red] mb-5'>{t('does_not_comply_with_phytosanitary_requirements')}</h4>
                ) : null
            }

            {
                isLoading ? <Loader /> : (
                    <>
                        <Row gutter={[24, 24]}>
                            {
                                data?.map((item, index) => {
                                    return (
                                        <Col xl={6} lg={8} md={12} sm={24} xs={24} key={index}>
                                            <div className='custom-card p-4 h-[100%] flex flex-col'>
                                                <p>{t('name')} <b>{item?.company_name}</b></p>
                                                <p>{t('address')} <b>{item?.company_address}</b></p>
                                                <p className='m-0'>{t('phone')} <b>{item?.company_phone_number ?? '-'}</b></p>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>

                        {
                            data?.results?.length === 0 ? <Empty /> : null
                        }
                    </>
                )
            }

            {isError ? <Error /> : null}
        </div>
    )
}

export default InnerZoneCountryDetail