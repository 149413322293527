import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Col, Row, Pagination, Select, Empty } from 'antd'
import Loader from '../loader'
import Error from '../error'
import { useTranslation } from 'react-i18next'
import http from '../../utils/helpers'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

const Documents = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const [category, setCategory] = useState(null)

    const filter = useMemo(() => {
        return {
            page: currentPage,
            page_size: pageSize,
            category
        }
    }, [currentPage, pageSize, category])

    const { data, isLoading, isError } = useQuery({
        queryKey: ['agro-minerals', filter],
        queryFn: async () => {
            return await http.get(`common/rules/`, {
                params: filter,
            })
        },
        placeholderData: keepPreviousData
    })

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-5 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{t('normative_legal_documents')}</h2>
            </div>

            <div className='text-right mb-5 flex justify-between items-center'>
                <span>{t('total')} {data?.count} {t('ta')}</span>
                <Select
                    placeholder={t('categories')}
                    value={category}
                    options={[
                        { value: 'qonun', label: 'Qonun' },
                        { value: 'qaror', label: 'Qaror' },
                        { value: 'farmon', label: 'Farmon' },
                        { value: 'document', label: 'Idoraviy normativ-huquqiy hujjatlar' }
                    ]}
                    onChange={(e) => setCategory(e)}
                    allowClear
                    className='w-[300px] text-left'
                />
            </div>

            {
                isLoading ? <Loader /> : (
                    <>
                        <Row gutter={[24, 24]}>
                            {
                                data?.results?.map((item, index) => {
                                    return (
                                        <Col xl={6} lg={8} md={8} sm={12} xs={24} key={index}>
                                            <div className='h-[100%] custom-card p-4 flex flex-col'>
                                                <a
                                                    href={item?.link}
                                                    target='_blank'
                                                    rel="noreferrer"
                                                    className='mb-3 text-[16px]'
                                                    style={{ lineHeight: '20px' }}
                                                >
                                                    {item?.description}
                                                </a>

                                                <div className='mt-auto flex justify-between'>
                                                    <span style={{ textTransform: "uppercase" }}>{item?.category}</span>
                                                    <span>{item?.date}</span>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>

                        {
                            data?.results?.length === 0 ? <Empty /> : null
                        }

                        {
                            Array.isArray(data?.results) ? (
                                <div className='text-right mt-5'>
                                    <Pagination
                                        defaultCurrent={currentPage}
                                        current={currentPage}
                                        pageSize={pageSize}
                                        showSizeChanger={true}
                                        pageSizeOptions={[20, 50, 100]}
                                        onChange={(current, size) => {
                                            setCurrentPage(current)
                                            setPageSize(size)
                                        }}
                                        total={data?.count}
                                    />
                                </div>
                            ) : null
                        }
                    </>
                )
            }

            {isError ? <Error /> : null}
        </div>
    )
}

export default Documents