import dayjs from "dayjs";

export const makeOptions = (data, label, value = 'id') => {
    if (!data) {
        return [];
    }
    return data?.map((item) => ({
        label: typeof label === 'function' ? label(item) : item[label],
        value: item[value],
    })) || [];
};

export function extractVideoId(url) {
    const regExp1 = /v=([a-zA-Z0-9_-]+)/;
    const regExp2 = /youtu\.be\/([a-zA-Z0-9_-]+)/;

    const match1 = url.match(regExp1);
    const match2 = url.match(regExp2);

    if (match1 && match1.length > 1) {
        return match1[1];
    } else if (match2 && match2.length > 1) {
        return match2[1];
    } else {
        return "Invalid URL";
    }
}

export const setUTCTime = (date, template) => {
    return dayjs(new Date(date)).format(template || 'YYYY-MM-DD H:mm')
}