import { Col, Row } from 'antd'
import queryString from 'query-string'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import localSeed from '../../assets/images/local_seed.png'
import importSeed from '../../assets/images/import_seed.png'
import { useTranslation } from 'react-i18next'

const Seeds = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const items = [
        {
            type: "local_seed",
            title: t('local_seed'),
            image: localSeed,
            link: 'minerals'
        },
        {
            type: "import_seeds",
            title: t('import_seed'),
            image: importSeed,
            link: 'minerals'
        },
    ]

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-5 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{t('seeds')}</h2>
            </div>

            <div className='text-right mb-5 flex justify-between items-center'>
                <span>{t('total')} {items?.length} {t('ta')}</span>
            </div>

            <Row gutter={[24, 24]}>
                {
                    items?.map((item, index) => {
                        return (
                            <Col xl={4} lg={6} md={8} sm={8} xs={12} key={index}>
                                <div
                                    className='cursor-pointer'
                                    onClick={() => {
                                        navigate(`${item?.type}/?${queryString.stringify({ title: item?.title })}`)
                                    }}
                                >
                                    <div className='custom-card p-4 h-[100%] flex items-center flex-col'>
                                        <div
                                            className='w-[60px] h-[70px] mx-auto mb-3'
                                            style={{ maskImage: `url(${item?.image})`, backgroundColor: "green" ?? 'black', maskRepeat: 'no-repeat', maskPosition: 'center', maskSize: 'contain' }}
                                        ></div>
                                        <h3 className='m-0 text-[#147461]'>{item?.title}</h3>
                                    </div>
                                </div>
                            </Col>
                        )
                    })
                }
            </Row>
        </div>
    )
}

export default Seeds