// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-card {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border-radius: 8px;
  background-color: #00000005;
  height: 100%;
  overflow-x: auto;
  transition: 0.3s;
}

.custom-card:hover {
  transform: translateY(-10px);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
`, "",{"version":3,"sources":["webpack://./src/components/card/card.css"],"names":[],"mappings":"AAAA;EACE;0CACwC;EACxC,kBAAkB;EAClB,2BAA2B;EAC3B,YAAY;EACZ,gBAAgB;EAIhB,gBAAgB;AAHlB;;AAMA;EACE,4BAA4B;EAC5B;uCACqC;AACvC","sourcesContent":[".custom-card {\n  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,\n    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;\n  border-radius: 8px;\n  background-color: #00000005;\n  height: 100%;\n  overflow-x: auto;\n}\n\n.custom-card {\n  transition: 0.3s;\n}\n\n.custom-card:hover {\n  transform: translateY(-10px);\n  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,\n    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
