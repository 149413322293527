import { Button, Form, Input, Modal } from 'antd'
import React from 'react'
import supplyDemandImg from '../../assets/images/supply-demand.png'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import http from '../../utils/helpers'
import { toast } from 'react-toastify'

const SupplyAndDemandModal = (props) => {
    const { t } = useTranslation()

    const { isOpenModal, setIsOpenModal } = props

    const { mutate: sendMessage, isPending } = useMutation({
        mutationKey: ['common-suggestions'],
        mutationFn: async (data) => {
            const response = await http.post(`/common/suggestion/`)
            return response
        },
        onSuccess: () => {
            toast.success(t('successfully'))
            setIsOpenModal(false)
        },
        onError: () => {
            toast.error(t('error'))
        }
    }
    )

    const onFinish = (values) => {
        sendMessage(values)
    }

    return (
        <Modal
            open={isOpenModal}
            onCancel={() => setIsOpenModal(false)}
            footer={null}
            width={800}
            centered
            className='p-5'
        >
            <div className='text-center'>
                <img src={supplyDemandImg} alt='' className='w-[60px]' />
            </div>
            <div>
                <h5 className='text-center mx-auto text-[18px] my-5 w-[80%]'>{t('supply-demand')}</h5>
            </div>

            <Form
                name="leave-request"
                style={{
                    maxWidth: 600,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off"
                layout='vertical'
                className='mx-auto text-center'
            >
                <Form.Item
                    name="message"
                    rules={[
                        {
                            required: true,
                            message: 'Please input!',
                        },
                    ]}
                >
                    <Input.TextArea size='large' placeholder={t('leave_comment')} />
                </Form.Item>

                <Button
                    className='xl:w-[50%] lg:w-[50%] md:w-[80%] sm:w-[100%] w-[100%]'
                    size='large'
                    type="primary"
                    htmlType="submit"
                    loading={isPending}
                >
                    {t('send')}
                </Button>
            </Form>
        </Modal>
    )
}

export default SupplyAndDemandModal