import React, { useMemo, useState } from 'react'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import http from '../../utils/helpers'
import { Col, Row, Input, Empty, Pagination } from 'antd'
import Loader from '../loader'
import Error from '../error'
import queryString from 'query-string'
import { extractVideoId } from '../../utils'
import { useTranslation } from 'react-i18next'

const UsefulVideos = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [search, setSearch] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)

    const filter = useMemo(() => {
        return {
            search,
            page: currentPage,
            page_size: pageSize
        }
    }, [search, currentPage, pageSize])

    const { data, isLoading, isError } = useQuery({
        queryKey: ['common-videos', filter],
        queryFn: async () => {
            return await http.get(`common/v2/videos/`, {
                params: filter
            })
        },
        placeholderData: keepPreviousData
    })

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-5 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{t('useful_videos')}</h2>
            </div>

            <div className='text-right mb-5 flex justify-between items-center'>
                <span>{t('total')} {data?.count} {t('ta')}</span>
                <Input
                    placeholder={t('search')}
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    className='w-[250px] text-left'
                />
            </div>

            {
                isLoading ? <Loader /> : (
                    <>
                        <Row gutter={[24, 24]}>
                            {
                                data?.results?.map((item, index) => {
                                    return (
                                        <Col xl={24} lg={24} md={24} sm={24} xs={24} key={index}>
                                            <div
                                                className='cursor-pointer'
                                                onClick={() => {
                                                    navigate(`video/?${queryString.stringify({ title: item?.title, video_type: item?.video_type, video: item?.video_type === "youtube" ? extractVideoId(item?.video_url) : item?.video, content: item?.content_uz })}`)
                                                }}
                                                style={{ borderBottom: '1px solid #00000030' }}
                                            >
                                                <div className='pb-5 flex items-center justify-start'>
                                                    <img src={`https://img.youtube.com/vi/${extractVideoId(item?.video_url)}/0.jpg`} alt={item?.title} className='xl:w-[120px] lg:w-[100px] md:w-[90px] sm:w-[80px] w-[60px] xl:mr-5 mr-3' />
                                                    <h3 className='m-0 text-left'>{item?.title}</h3>
                                                    <div className='ml-auto arrow-icon' style={{ transform: 'rotate(180deg)' }}></div>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>

                        {
                            data?.results?.length === 0 ? <Empty /> : null
                        }

                        {
                            Array.isArray(data?.results) ? (
                                <div className='text-right mt-5'>
                                    <Pagination
                                        defaultCurrent={currentPage}
                                        current={currentPage}
                                        pageSize={pageSize}
                                        showSizeChanger={true}
                                        pageSizeOptions={[20, 50, 100]}
                                        onChange={(current, size) => {
                                            setCurrentPage(current)
                                            setPageSize(size)
                                        }}
                                        total={data?.count}
                                    />
                                </div>
                            ) : null
                        }
                    </>
                )
            }

            {isError ? <Error /> : null}
        </div>
    )
}

export default UsefulVideos