import { Button, Result } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
    return (
        <div className='w-[100%] h-[100vh] flex items-center justify-center' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <Result
                status="404"
                title="404"
                subTitle="Kechirasiz, sahifa topilmadi."
                extra={
                    <Link to='/'>
                        <Button type="primary">Bosh sahifaga qaytish</Button>
                    </Link>
                }
            />
        </div>
    )
}

export default NotFound