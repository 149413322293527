import { Button, Form, Input, Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import inspektorImage from '../../assets/images/inspektor_icon.png'

const CallInspector = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const onFinish = (values) => {
        console.log('Success:', values)
    }

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-5 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{t('call_the_inspector')}</h2>
            </div>

            <div className='text-center my-5'>
                <img src={inspektorImage} alt='' className='w-[200px]' />
            </div>

            <Form
                name="call-inspector"
                style={{
                    maxWidth: 600,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off"
                layout='vertical'
                className='mx-auto'
            >
                <Form.Item
                    label={t('fullname')}
                    name="fullname"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your fullname!',
                        },
                    ]}
                >
                    <Input size='large' placeholder={t('fullname')} />
                </Form.Item>

                <Form.Item
                    label={t('phone_number')}
                    name="phone"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your phone!',
                        },
                    ]}
                >
                    <Input size='large' type='number' placeholder={t('phone_number')} />
                </Form.Item>

                <Form.Item
                    label={t('select_city_region')}
                    name="phone"
                    rules={[
                        {
                            required: true,
                            message: 'Please select your region!',
                        },
                    ]}
                >
                    <Select
                        placeholder={t('select_city_region')}
                        size='large'
                        allowClear
                    />
                </Form.Item>

                <Form.Item>
                    <Button className='w-[100%]' size='large' type="primary" htmlType="submit">
                        {t('send')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default CallInspector