import React from 'react'
import headerLogo from '../../assets/images/header-logo.png'
import './header.css'
import { Link } from 'react-router-dom'
import flagUz from '../../assets/images/uzb-flag.png'
import flagRu from '../../assets/images/rus-flag.png'
import flagEn from '../../assets/images/eng-flag.png'
import Cookies from 'js-cookie'
import { changeLanguage } from 'i18next'
import { useTranslation } from 'react-i18next'

const Header = () => {

    const { t } = useTranslation()
    const initialLang = Cookies.get('i18next')

    const changeLan = (lang) => {
        changeLanguage(lang)
        window.location.reload()
    }

    return (
        <>
            <div>
                <div className='p-2 container mx-auto' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div className='ml-5 flex cursor-pointer' onClick={() => changeLan('uz')}>
                        <img src={flagUz} alt='' className='w-[20px] mr-2' />
                        <span className={initialLang === 'uz' ? 'text-[12px] text-[green]' : 'text-[12px]'}>O'Z</span>
                    </div>

                    <div className='ml-5 flex cursor-pointer' onClick={() => changeLan('uzb')}>
                        <img src={flagUz} alt='' className='w-[20px] mr-2' />
                        <span className={initialLang === 'uzb' ? 'text-[12px] text-[green]' : 'text-[12px]'}>ЎЗ</span>
                    </div>

                    <div className='ml-5 flex cursor-pointer' onClick={() => changeLan('ru')}>
                        <img src={flagRu} alt='' className='w-[20px] mr-2' />
                        <span className={initialLang === 'ru' ? 'text-[12px] text-[green]' : 'text-[12px]'}>РУ</span>
                    </div>

                    <div className='ml-5 flex cursor-pointer' onClick={() => changeLan('en')}>
                        <img src={flagEn} alt='' className='w-[20px] mr-2' />
                        <span className={initialLang === 'en' ? 'text-[12px] text-[green]' : 'text-[12px]'}>EN</span>
                    </div>
                </div>
            </div>
            <div className='py-5 header'>
                <div className='container inner-header'>
                    <Link to='/'>
                        <div className='header-top'>
                            <img src={headerLogo} alt='header-logo' className='logo' />
                            <h5>{t('header')}</h5>
                        </div>
                    </Link>

                    <div className='header-info header-info1'>
                        <p>
                            <b className='mr-2'>{t('our_address')}</b>
                            {t('address_header')}
                        </p>
                        <p>
                            <b className='mr-2'>{t('email_header')}</b>
                            <a href='mailto:info@karantin.uz' className='text-[white]'>info@karantin.uz</a>
                        </p>
                        <p className='m-0'>
                            <b className='mr-2'>{t('anti_corrupt')}</b>
                            <a href='https://t.me/uzdavkarantin_antikor_bot' target='_blank' rel="noreferrer" className='text-[white]'>@uzdavkarantin_antikor_bot</a>
                        </p>
                    </div>

                    <div className='header-info'>
                        <p>
                            <b className='mr-2'>{t('ishonch_tel')}</b>
                            <br />
                            <a href='tel:(+99871) 202 84 84' className='text-[white] text-[18px]'>(+99871) 202 84 84</a>
                        </p>
                        <p>
                            <b className='mr-2'>{t('anti_corrupt')}</b>
                            <br />
                            <a href='tel:(+99871) 202 64 64' className='text-[white] text-[18px]'>(+99871) 202 64 64</a>
                        </p>
                        <p className='m-0'>
                            <b className='mr-2'>{t('call_center')}</b>
                            <br />
                            <a href='tel:1288' className='text-[white] text-[18px] mr-2'>1288,</a>
                            <a href='tel:(+99871) 202 10 00' className='text-[white] text-[18px]'>(+99871) 202 10 00</a>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header