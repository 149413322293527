import { Row } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import localPricesImage from '../../assets/images/local_prices.png'
import abroadPricesImage from '../../assets/images/abroad_prices.png'
import { useTranslation } from 'react-i18next'
import Card from '../../components/card'

const Prices = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const items = [
        {
            id: null,
            title: t('local_prices'),
            image: localPricesImage,
            color: 'blue',
            url: 'https://akk.karantin.uz/api/v2/common/url/export_price/'
        },
        {
            id: null,
            title: t('abroad_prices'),
            image: abroadPricesImage,
            color: 'blue',
            link: 'abroad-prices',
        }
    ]

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-5 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate('/')}></div>
                <h2 className='m-0'>{t('fruits')}</h2>
            </div>

            <div className='text-right mb-5 flex justify-between items-center'>
                <span>{t('total')} {items?.length} {t('ta')}</span>
            </div>

            <Row gutter={[24, 24]}>
                {
                    items?.map((item, index) => {
                        return (
                            <Card key={index} item={item} url={item?.url} />
                        )
                    })
                }
            </Row>
        </div>
    )
}

export default Prices