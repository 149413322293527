import { Button, Col, Modal, Row, Table } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

const CheckedCertificateDetailModal = (props) => {
    const { isOpenModal, setIsOpenModal, data } = props
    const { t } = useTranslation()

    const columns = [
        {
            title: t('form_number'),
            dataIndex: 'number',
            key: 'number',
            width: 250,
            align: 'center',
        },
        {
            title: t('certificate_issued_date'),
            dataIndex: 'given_date',
            key: 'given_date',
            width: 250,
            align: 'center',
        },
        {
            title: t('certificate_validity_period'),
            dataIndex: 'expire_date',
            key: 'expire_date',
            width: 250,
            align: 'center',
        },
        {
            title: t('applicant'),
            dataIndex: 'applicant',
            key: 'applicant',
            width: 250,
            align: 'center',
        },
        {
            title: t('designated_country'),
            dataIndex: 'country',
            key: 'country',
            width: 250,
            align: 'center',
        },
        {
            title: t('type_of_vehicle'),
            dataIndex: 'transport_method',
            key: 'transport_method',
            width: 250,
            align: 'center',
        },
        {
            title: t('controller'),
            dataIndex: 'inspector',
            key: 'inspector',
            width: 250,
            align: 'center',
        },
        {
            title: t('exporting_country'),
            dataIndex: 'exporter',
            key: 'exporter',
            width: 250,
            align: 'center',
        },
        {
            title: t('exporter_address'),
            dataIndex: 'exporter_address',
            key: 'exporter_address',
            width: 250,
            align: 'center',
        },
        {
            title: t('manufacturer_field'),
            dataIndex: 'manufacturer_field_url_address',
            key: 'manufacturer_field_url_address',
            width: 250,
            align: 'center',
        },
    ]

    return (
        <Modal
            open={isOpenModal}
            onCancel={() => setIsOpenModal(false)}
            footer={null}
            width={1500}
            centered
            className='p-5'
        >
            <Table
                dataSource={[{ ...data, key: 1 }]}
                columns={columns}
                pagination={false}
                className='my-10'
                size='small'
                scroll={{
                    x: 'scroll',
                    y: '500px'
                }}
            />
            <Row gutter={[24, 24]}>
                {
                    data?.products?.map((item, index) => {
                        return (
                            <Col xl={6} lg={8} md={12} sm={12} xs={24} key={index}>
                                <div className='custom-card h-[100%] p-4 flex flex-col'>
                                    <p>TIF TN: <b>{item?.hs_code}</b></p>
                                    <p>{t('product_name')} <b>{item?.name}</b></p>
                                    <p>{t('weight')} <b>- -</b></p>
                                    <p className='m-0'>{t('net_weight')} <b>{item?.net_quantity} {item?.net_quantity_unit}</b></p>
                                </div>
                            </Col>
                        )
                    })
                }
            </Row>

            <div className='text-center mt-5'>
                <a href={data?.downloadable_link} target='_blank' rel="noreferrer">
                    <Button className='px-10' type='primary'>{t('download_the_certificate')}</Button>
                </a>
            </div>
        </Modal >
    )
}

export default CheckedCertificateDetailModal