// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/header-pattern.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  width: calc(100% - 6px);
  margin: auto;
  background-color: #5db075;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  border-radius: 16px;
}

.inner-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

.header a {
  text-decoration: none;
}

.header-top {
  display: flex;
  width: 370px;
  align-items: center;
}

.logo {
  width: 100px;
}

.header-top h5 {
  font-size: 16px;
  margin: 0;
  margin-left: 10px;
  color: #fff;
  letter-spacing: 0.8px;
  line-height: 20px;
}

.header .header-info p {
  font-size: 14px;
  color: #fff;
}

.header .header-info b {
  line-height: 20px;
}

@media (max-width: 992px) {
  .inner-header {
    display: flex;
    flex-direction: column;
  }

  .header .header-info {
    margin: 10px;
    text-align: center;
  }

  .header .header-info1 {
    display: none;
  }
}

@media (max-width: 768px) {
  .header {
    padding: 20px;
  }

  .logo {
    width: 60px;
  }

  .header-top {
    width: 300px;
  }

  .header-top h5 {
    font-size: 12px;
    margin-left: 5px;
    color: #fff;
    letter-spacing: 0.8px;
    line-height: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/header/header.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,YAAY;EACZ,yBAAyB;EACzB,yDAA+D;EAC/D,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,SAAS;EACT,iBAAiB;EACjB,WAAW;EACX,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,qBAAqB;IACrB,iBAAiB;EACnB;AACF","sourcesContent":[".header {\n  width: calc(100% - 6px);\n  margin: auto;\n  background-color: #5db075;\n  background-image: url(\"../../assets/images/header-pattern.png\");\n  border-radius: 16px;\n}\n\n.inner-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin: auto;\n}\n\n.header a {\n  text-decoration: none;\n}\n\n.header-top {\n  display: flex;\n  width: 370px;\n  align-items: center;\n}\n\n.logo {\n  width: 100px;\n}\n\n.header-top h5 {\n  font-size: 16px;\n  margin: 0;\n  margin-left: 10px;\n  color: #fff;\n  letter-spacing: 0.8px;\n  line-height: 20px;\n}\n\n.header .header-info p {\n  font-size: 14px;\n  color: #fff;\n}\n\n.header .header-info b {\n  line-height: 20px;\n}\n\n@media (max-width: 992px) {\n  .inner-header {\n    display: flex;\n    flex-direction: column;\n  }\n\n  .header .header-info {\n    margin: 10px;\n    text-align: center;\n  }\n\n  .header .header-info1 {\n    display: none;\n  }\n}\n\n@media (max-width: 768px) {\n  .header {\n    padding: 20px;\n  }\n\n  .logo {\n    width: 60px;\n  }\n\n  .header-top {\n    width: 300px;\n  }\n\n  .header-top h5 {\n    font-size: 12px;\n    margin-left: 5px;\n    color: #fff;\n    letter-spacing: 0.8px;\n    line-height: 16px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
