import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import http from '../../utils/helpers'
import Loader from '../loader'
import Error from '../error'
import { Segmented } from 'antd'
import { PhotoProvider, PhotoView } from 'react-photo-view'

const ThirdInnerPage = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { title } = queryString.parse(window.location.search)

    const { data, isLoading, isError } = useQuery({
        queryKey: ['product-detail', params.id],
        queryFn: async () => {
            return await http.get(`agro/new/product/details/${params.id}/`)
        }
    })

    const [activeTab, setActiveTab] = useState(data?.more_info[0]?.title)

    useEffect(() => {
        setActiveTab(data?.more_info[0]?.title)
    }, [data])

    if (isLoading) {
        return <Loader />
    }
    if (isError) {
        return <Error />
    }

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-10 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{title}</h2>
            </div>

            <h3 className='mb-5'>{data?.title}</h3>

            <div className='mb-3 flex justify-center' style={{ overflowX: 'auto' }}>
                <PhotoProvider>
                    {
                        data?.images?.map((item, index) => {
                            return (
                                <div key={index} >
                                    <PhotoView key={index} src={item.image}>
                                        <img src={item?.image} alt="" className='block m-2 w-[350px] h-[250px]' style={{ borderRadius: '12px' }} />
                                    </PhotoView>
                                </div>
                            )
                        })
                    }
                </PhotoProvider>
            </div>

            <div style={{ overflowX: 'auto' }}>
                <Segmented
                    defaultValue="center"
                    style={{
                        marginBottom: 16,
                    }}
                    onChange={(value) => setActiveTab(value)}
                    value={activeTab}
                    options={data?.more_info?.map(item => item.title)}
                />
            </div>
            {
                <div className='text-[18px]' style={{ lineHeight: '28px' }}>
                    {data?.more_info?.filter((item) => item?.title === activeTab)[0]?.description}
                </div>
            }
        </div>
    )
}

export default ThirdInnerPage